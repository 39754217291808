import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
const ComponentName = () => {
    const data = useStaticQuery(graphql`{
      site {
          siteMetadata {
              menu {
                  menuItems {
                      label
                      link
                  }
              }
              logo
              url
              title
              summary
              author {
                address
                affiliation
                email
                name
                social {
                  name
                  url
                  icon
                }
                summary
                telephone
              }
          }
      }
    }`)
    return (
        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <address class="contact-mod">
                            <h4>Kontakt</h4>
                            <p>{data.site.siteMetadata.author.affiliation}</p>
                            <p>{data.site.siteMetadata.author.name}</p>
                            <p>adres: {data.site.siteMetadata.author.address}</p>
                            <p>tel.: <Link to={"tel:" + data.site.siteMetadata.author.email} >{data.site.siteMetadata.author.telephone}</Link></p>
                            <p>e-mail: <Link to={"mailto:" + data.site.siteMetadata.author.email} >{data.site.siteMetadata.author.email}</Link></p>
                        </address>
                    </div>
                    <div class="col-md-5">
                        <div class="footer-links">
                        <ul class="footer-group">
                            {data.site.siteMetadata.menu.menuItems.map(( item ) => (
                            <li className={item.id}>
                                <Link to={item.link}>
                                    {item.label}
                                </Link>
                            </li>
                            ))}
                        </ul>
                        <p>{data.site.siteMetadata.summary}</p>
                        <p>Copyright © 2015 <a href="https://25wat.com">25wat.com</a></p>

                        </div>
                    </div>
                    <div class="social-share">
                        {data.site.siteMetadata.author.social.map(( item ) => (
                            <Link className={item.name + "-share"} to={item.url}>
                                <img width="22w" height="17.5w" className="logo" src={item.icon} alt={item.name} />
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default ComponentName
