import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import logo from '../../content/assets/sedna-logo.png';

const ComponentName = () => {
    const data = useStaticQuery(graphql`{
      site {
          siteMetadata {
              menu {
                  menuItems {
                      label
                      link
                  }
              }
              logo
              url
              title
              author {
                address
                affiliation
                email
                name
                social {
                  name
                  url
                  icon
                }
                summary
                telephone
              }
          }
      }
    }`)
    const [toggleNav, setToggleNav] = React.useState(false)
    return (
      <section className="navigation">
        <header id="header">
          <div className="header-content">
            <div className="logo">
                <Link to="/">
                  <img className="logo" src={logo} alt={data.site.siteMetadata.title} />
                </Link>
            </div>
            <div className={`header-nav ${toggleNav ? `open` : `` }`} >
              <nav>
                  <ul className="primary-nav">
                      {data.site.siteMetadata.menu.menuItems.map(( item ) => (
                      <li className={item.id}>
                          <Link to={item.link} className="mobileCloseOnClick">
                              {item.label}
                          </Link>
                      </li>
                      ))}
                  </ul>
                  <ul className="member-actions social-share-menu">
                      <li>
                      {data.site.siteMetadata.author.social.map(( item ) => (
                          <Link className={item.name + "-share"} to={item.url}>
                            <img width="22w" height="17.5w" className="logo" src={item.icon} alt={item.name} />
                          </Link>
                      ))}
                      </li>
                  </ul>
              </nav>
            </div>
            <div className="navicon">
              <Link className={`nav-toggle ${toggleNav ? `active` : `` }`} 
                to={`#top`}
                onClick={e => {
                  e.preventDefault();
                  setToggleNav(!toggleNav)
                }}>
                  <span></span>
              </Link>
            </div>
          </div>
        </header>
      </section>
    )
}

export default ComponentName
