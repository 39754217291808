import React from 'react';
import { Link } from 'gatsby';
const ComponentName = () => {
    const [toggleCookie, setCookieConsent] = React.useState(false)
    return (
        <section className={`cookie ${toggleCookie ? `hidden` : `visible` }`}>
            <div className="left">
                <p>W ramach naszej witryny stosujemy pliki cookies w celu świadczenia Państwu usług na najwyższym poziomie, w tym w sposób dostosowany do indywidualnych potrzeb. Korzystanie z witryny bez zmiany ustawień dotyczących cookies oznacza, że będą one zamieszczane w Państwa urządzeniu końcowym.</p>
            </div>
            <Link 
                id="hideCookie" 
                className={`js_cookie_agree ${toggleCookie ? `accept` : `deny` }`} 
                to={"#"}
                onClick={e => {
                e.preventDefault();
                setCookieConsent(!toggleCookie)
                }}>
                <p>OK<span>, ROZUMIEM</span></p>
            </Link>   
        </section>
    )
}

export default ComponentName
