import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from 'prop-types'
import SideBar from './navbar'
import Footer from './footer'
import Cookie from './cookie'

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreloaded: true,
    };
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ isPreloaded: false });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
  
  render() {
    const { children, fullMenu } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <div className="global-wrapper front">
              <SideBar fullMenu={fullMenu}/>
              {children}
              <Footer />
              <Cookie />
            </div>
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
